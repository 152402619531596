import { BaseController } from "./base_controller";

const lunr = require("lunr");
require("lunr-languages/lunr.stemmer.support")(lunr);
require("lunr-languages/lunr.es")(lunr);

export default class extends BaseController {
  static targets = ["q", "results", "form", "title", "result", "noResult"];

  get q() {
    const q = new URLSearchParams(window.location.search).get("q");

    return q?.trim()?.replaceAll(/[:~\*\^\+\-]/gi, "");
  }

  qTargetConnected(qTarget) {
    const q = this.q;
    if (q) qTarget.value = this.q;
  }

  async resultsTargetConnected(resultsTarget) {
    const q = this.q;

    if (!q) return;

    document.title = `${document.title.split(":")[0]}: ${q}`;

    if (this.hasTitleTarget) this.titleTarget.innerText = q;

    await this.fetch();

    if (!window.index) return;

    const results = window.index.search(q.split(" ").map(x => `+${x}`).join(" "))
      .map((r) => window.data.find((d) => d.id === r.ref))
      .sort((a, b) => a.xml_date < b.xml_date ? 1 : -1);

    resultsTarget.innerHTML = "";

    if (!results[0]) {
      resultsTarget.appendChild(this.applyTemplate({}, this.noResultTarget));
    } else {

      for (const result of results) {
        const data = {
          image: result.image?.path,
          title: result.title,
          url: result.id,
          date: result.formatted_date,
          tags: result.tags,
          xmlDate: result.xml_date,
        };

        resultsTarget.appendChild(this.applyTemplate(data, this.resultTarget));
      }
    }
  }

  async fetch() {
    // Solo permite descargar uno a la vez
    if (this.fetching) return;

    this.fetching = true;
    let response;

    // Si no existe el índice, lo descarga y procesa Lunr
    if (!window.data) {
      response = await fetch("data.json");
      window.data = await response.json();
    }

    if (!window.index) {
      response = await fetch("idx.json");
      const idx = await response.json();
      window.index = lunr.Index.load(idx);
    }

    // Permitir volver a ejecutar
    this.fetching = false;
  }

  set formDisable(disable) {
    if (!this.hasFormTarget) return;

    this.formTarget.elements.forEach((x) => (x.disabled = disable));
  }
}
