import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.url = new URL(window.location);
    this.lastActiveItem = null;

    if (this.url.hash) {
      this.lastActiveItem = this.items[this.url.hash.split("#")[1]];
      if (this.lastActiveItem) this.activate(this.lastActiveItem);
    }
  }

  itemTargetConnected(itemTarget) {
    if (!this.items) this.items = {};

    this.items[itemTarget.dataset.scrollSection] = itemTarget;
  }

  itemTargetDisconnected(itemTarget) {
    delete this.items[itemTarget.dataset.scrollSection];

    if (this.lastActiveItem === itemTarget) this.lastActiveItem = null;
  }

  activate(item) {
    item.classList.add("active");
    item.setAttribute("aria-current", true);
  }

  deactivate(item) {
    item.classList.remove("active");
    item.setAttribute("aria-current", false);
  }

  update({ detail }) {
    const currentItem = this.items[detail.id];

    if (!currentItem) return;
    if (this.lastActiveItem === currentItem) return;

    if (this.lastActiveItem) this.deactivate(this.lastActiveItem);

    this.activate(currentItem);

    this.url.hash = `#${detail.id}`;

    if (window.location.hash !== this.url.hash)
      window.history.pushState({}, "", this.url);

    this.lastActiveItem = currentItem;
  }
}
