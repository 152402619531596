import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["can", "cannot"];
  static values = {
    title: String,
    text: String,
    url: String,
  };

  get shareData() {
    if (!this._shareData) {
      const title = this.titleValue;
      const text = this.textValue;
      const url = this.urlValue;

      this._shareData = { title, text, url };
    }

    return this._shareData;
  }

  get canShare() {
    if (!this._canShare) {
      this._canShare =
        "share" in navigator && navigator.canShare(this.shareData);
    }

    return this._canShare;
  }

  canTargetConnected(canTarget) {
    if (this.canShare) canTarget.classList.remove("d-none");
  }

  cannotTargetConnected(cannotTarget) {
    if (this.canShare) cannotTarget.classList.add("d-none");
  }

  async share(event = undefined) {
    try {
      await navigator.share(this.shareData);
    } catch (e) {
      switch (e.name) {
        case "AbortError":
          break;
        default:
          console.error(e);
      }
    }
  }
}
