import { BaseController } from "./base_controller";

export default class extends BaseController {
  static values = {
    title: String,
    id: String,
  };

  titleValueChanged (newValue, oldValue) {
    if (oldValue === newValue) return;
    if (!newValue) return;

    this.element.innerText = newValue;
  }

  idValueChanged (newValue, oldValue) {
    if (oldValue === newValue) return;
    if (!newValue) return;

    this.element.href = newValue;
  }
}
