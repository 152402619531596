import { Controller } from "@hotwired/stimulus";

/*
 * Al navegar por el sitio y llegar a ciertas secciones, se van
 * activando ítems del menú.
 *
 * Para eso configuramos un IntersectionObserver en todo el documento y
 * a medida que van apareciendo secciones actualizamos el menú.
 */
export default class extends Controller {
  static targets = ["section"];

  connect() {}

  sectionTargetConnected(sectionTarget) {
    this.observer.observe(sectionTarget);
  }

  sectionTargetDisconnected(sectionTarget) {
    this.observer.unobserve(sectionTarget);
  }

  disconnect() {
    this.observer.disconnect();
  }

  /*
   * Solo nos interesa la primera
   */
  get observer() {
    if (!this._observer)
      this._observer = new IntersectionObserver(
        (entries, observer) => this.update(entries),
        { threshold: 0.0, rootMargin: "0px" }
      );

    return this._observer;
  }

  update(entries) {
    const section = entries.find((x) => x.intersectionRatio > 0);

    if (!section) return;

    const detail = { id: section.target.id };

    window.dispatchEvent(new CustomEvent("scroll-section", { detail }));
  }
}
