import { BaseController } from "./base_controller";

export default class extends BaseController {
  static targets = ["date", "title", "url", "tags", "tag", "image"];
  static values = {
    image: String,
    title: String,
    url: String,
    date: String,
    xmlDate: String,
    tags: Array,
  };

  titleValueChanged (newValue, oldValue) {
    if (!newValue) return;
    if (oldValue === newValue) return;

    for (const titleTarget of this.titleTargets) {
      titleTarget.innerText = newValue;
    }
  }

  imageValueChanged (newValue, oldValue) {
    if (!newValue) return;
    if (oldValue === newValue) return;

    for (const imageTarget of this.imageTargets) {
      imageTarget.src = newValue;
    }
  }

  urlValueChanged (newValue, oldValue) {
    if (!newValue) return;
    if (oldValue === newValue) return;

    for (const urlTarget of this.urlTargets) {
      urlTarget.href = newValue;
    }
  }

  dateValueChanged (newValue, oldValue) {
    if (!newValue) return;
    if (oldValue === newValue) return;

    for (const dateTarget of this.dateTargets) {
      dateTarget.innerText = newValue;
    }
  }

  xmlDateValueChanged (newValue, oldValue) {
    if (!newValue) return;
    if (oldValue === newValue) return;

    for (const dateTarget of this.dateTargets) {
      dateTarget.setAttribute("timestamp", newValue);
    }
  }

  tagsValueChanged (newValue, oldValue) {
    if (!newValue) return;
    if (newValue.length === 0) return;
    if (oldValue === newValue) return;

    this.tagsTarget.innerHTML = "";

    for (const tag of newValue) {
      this.tagsTarget.appendChild(
        this.applyTemplate(tag, this.tagTarget)
      );
    }
  }
}
