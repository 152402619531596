import { Controller } from "@hotwired/stimulus";
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";

export default class extends Controller {
  static targets = ["swiper", "prev", "next", "pagination", "slide", "bullet"];

  static values = {
    loop: { type: Boolean, default: true },
    effect: { type: String, default: "slide" },
    delay: { type: Number, default: 3000 },
    stop: { type: Boolean, default: true },
    slidesPerView: { type: String, default: "auto" },
    spaceBetween: { type: Number, default: 0 },
  };

  connect() {
    const modules = [];
    const loop = this.loopValue;
    const effect = this.effectValue;
    const delay = this.delayValue;
    const stopOnLastSlide = this.stopValue;
    const slidesPerView = this.slidesPerViewValue;
    const spaceBetween = this.spaceBetweenValue;
    const grabCursor = true;
    const centeredSlides = true;

    const options = {
      modules,
      loop,
      effect,
      grabCursor,
      slidesPerView,
      spaceBetween,
      centeredSlides,
    };

    if (this.hasNextTarget && this.hasPrevTarget) {
      const prevEl = this.prevTarget;
      const nextEl = this.nextTarget;

      options.modules.push(Navigation);
      options.navigation = { prevEl, nextEl };
    }

    if (this.hasPaginationTarget) {
      const el = this.paginationTarget;
      const clickable = true;
      const type = "bullets";

      options.modules.push(Pagination);
      options.pagination = { el, clickable, type };

      if (this.hasBulletTarget) {
        const bullet = this.bulletTarget;

        options.pagination.bulletClass = "bullet";
        options.pagination.bulletActiveClass = "background-white black";
        options.pagination.renderBullet = (index, className) => {
          bullet.querySelector("[data-number]").innerText = index + 1;

          return bullet.innerHTML;
        };
      }
    }

    if (delay > 0) {
      options.modules.push(Autoplay);
      options.autoplay = { delay, stopOnLastSlide };
    }

    this.swiper = new Swiper(this.swiperTarget, options);
  }

  prev(event = undefined) {
    event?.preventDefault();
    this.swiper?.slidePrev();
  }

  next(event = undefined) {
    event?.preventDefault();
    this.swiper?.slideNext();
  }
}
