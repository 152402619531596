import { Controller } from "@hotwired/stimulus";

export class BaseController extends Controller {
  /*
   * Visita una página con soporte para Turbo
   *
   * @param [String] URL
   */
  visit(url) {
    try {
      if (url.startsWith("http")) {
        throw "External";
      } else {
        Turbo.visit(url);
      }
    } catch {
      window.location = url;
    }
  }

  disableForm(formTarget, state) {
    if (!formTarget) return;

    formTarget.elements.forEach((x) => (x.disabled = state));
  }

  setCustomValidity (event) {
    event.target.setCustomValidity(event.params.customValidity);
  }

  unsetCustomValidity (event) {
    event.target.setCustomValidity("");
  }

  dispatchToWindow(name, detail = {}) {
    window.dispatchEvent(new CustomEvent(name, { detail }));
  }

  localizeNumber(string) {
    return parseFloat(string).toLocaleString(this.locale);
  }

  get locale () {
    return document.querySelector("html").lang;
  }

  applyTemplate(data, template = undefined) {
    if (!template) {
      console.log("default template");
      template = this.templateTarget;
    }

    if (!template) {
      console.error("Can't find template", data);
      return;
    }

    const elementContainer = template.content.firstElementChild.cloneNode(true);
    const controllers = elementContainer.dataset.controller?.split(" ") || [];

    for (const key in data) {
      const dasherizedKey = this.dasherize(key);

      let controllerValue;

      if (!data[key]) continue;

      switch (data[key].constructor.name) {
        case "Array":
        case "Object":
          controllerValue = JSON.stringify(data[key]);
          break;
        default:
          controllerValue = data[key];
      }

      for (const controller of controllers) {
        elementContainer.dataset[this.camelize(`${controller}-${key}-value`)] = controllerValue;
      }
    }

    return elementContainer;
  }

  // this comes from Stimulus but isn't exported
  camelize(value) {
    return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase());
  }

  // this comes from Stimulus but isn't exported
  dasherize(value) {
    return value.replace(/([A-Z])/g, (_, char) => `-${char.toLowerCase()}`);
  }
}
