import { Notifier } from "@airbrake/browser";

if (window.env.JEKYLL_ENV === "production") {
  try {
    window.airbrake = new Notifier({
      projectId: window.env.AIRBRAKE_PROJECT_ID,
      projectKey: window.env.AIRBRAKE_PROJECT_KEY,
      host: "https://panel.sutty.nl",
    });

    console.originalError = console.error;
    console.error = (...e) => {
      window.airbrake.notify(e.join(" "));
      return console.originalError(...e);
    };
  } catch (e) {
    console.error(e);
  }
}

import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "@hotwired/stimulus";
window.Stimulus = Application.start();

import BodyScrollController from "./controllers/body_scroll_controller";
import PlayerController from "./controllers/player_controller";
import ResultController from "./controllers/result_controller";
import SearchController from "./controllers/search_controller";
import StreamingController from "./controllers/streaming_controller";
import SwiperController from "./controllers/swiper_controller";
import TagController from "./controllers/tag_controller";
import FootnotesController from "./controllers/footnotes_controller";
import ScrollController from "./controllers/scroll_controller";
import ActiveScrollController from "./controllers/active_scroll_controller";
import ShareController from "./controllers/share_controller";

Stimulus.debug = (window.env.JEKYLL_ENV !== "production");
Stimulus.register("body-scroll", BodyScrollController);
Stimulus.register("player", PlayerController);
Stimulus.register("result", ResultController);
Stimulus.register("search", SearchController);
Stimulus.register("streaming", StreamingController);
Stimulus.register("swiper", SwiperController);
Stimulus.register("tag", TagController);
Stimulus.register("footnotes", FootnotesController);
Stimulus.register("scroll", ScrollController);
Stimulus.register("active-scroll", ActiveScrollController);
Stimulus.register("share", ShareController);
